<template>
  <div class="page-main">
    <!-- 进项详情主体 -->
    <div class="page-body" v-loading="recordLoading">
      <el-row class="subject">
        <div>详情</div>
      </el-row>
      <el-row class="prop-row" :gutter="20">
        <el-col :span="12">
          <div><span class="prop">组织名称：</span>{{ record.orgName }}</div>
        </el-col>
        <el-col :span="12">
          <div><span class="prop">组织税号：</span>{{ record.orgTaxNo }}</div>
        </el-col>
      </el-row>
      <el-row class="prop-row" :gutter="20">
        <el-col :span="12">
          <div>
            认证方式：
            <span>{{handDeductAuthorizeMode(record.authorizeMode)}}</span>
          </div>
        </el-col>
        <el-col :span="12">
          <div><span class="prop">启用状态：</span>{{record.enabled == true ? '启用' : '禁用'}}</div>
        </el-col>
      </el-row>
      <div v-show="record.authorizeMode=='ZS'">
        <el-row>
          <div class="split-line"></div>
        </el-row>
        <el-row class="prop-row" :gutter="20">
          <el-col :span="12">
            <div><span class="prop">助手账号：</span>{{ record.agentUsername }}</div>
          </el-col>
          <el-col :span="12">
            <div><span class="prop">助手密码：</span>{{ record.agentPassword }}</div>
          </el-col>
        </el-row>
        <el-row class="prop-row" :gutter="20">
          <el-col><span class="prop">税盘密码：</span>{{ record.password }}</el-col>
        </el-row>
        <el-row>
          <div class="split-line"></div>
        </el-row>
        <el-row>
          <div class="operateGuide">操作指引</div>
        </el-row>
        <el-row>
          <div class="operate">1.使用弹窗中的助手账号、助手密码登陆金财数智;</div>
          <div class="operate">2.将税盘插入。</div>
        </el-row>
      </div>
      <div v-show="record.authorizeMode=='RZS'">
        <el-row>
          <div class="split-line"></div>
        </el-row>
        <el-row class="prop-row" :gutter="20">
          <el-col><span
              class="prop">开通状态：</span><span>{{ record.activated ? '已开通' : '待开通' }}</span>
          </el-col>
        </el-row>
      </div>
      <div v-show="record.authorizeMode=='LS-JG-UKEY'">
        <el-row>
          <div class="split-line"></div>
        </el-row>
        <el-row class="prop-row" :gutter="20">
          <el-col><span class="prop">开票终端标识号：</span>{{ record.identifier }}</el-col>
        </el-row>
      </div>
    </div>
  </div>
</template>

<script>
import IncomeAuthorizeMode from "@/components/widgets/IncomeAuthorizeMode";
import {changeMethods} from "@/util/mixins";

const DEFT_RECORD = {
  id: '',
  name: '',
  taxNo: '',
  authorizeMode: 0,
  agentUsername: '',
  agentPassword: '',
  password: '',
  identifier: '',
  enabled: false,
  activated: false,
  value: ''
};
export default {
  name: "CertificationIncome",
  components: {
    IncomeAuthorizeMode,
  },
  mixins:{changeMethods},
  props: {
    record: {
      type: Object,
      required: true,
      default: () => {
        return DEFT_RECORD;
      }
    },
    recordLoading: {
      type: Boolean,
      default: false
    }
  },
  created() {
    console.log("进项详情页面，Created");
  },
  methods: {
    //认证方式
    handDeductAuthorizeMode(value) {
      let name = "";
      switch (value) {
        case "ZS":
          name = "助手";
          break;
        case "UK":
          name = "助手";
          break;
        case "RZS":
          name = "软证书";
          break;
        case "RPA":
          name = "SD-R-X";
          break;
        case "DZSJ":
          name = "电子税务局";
          break;
        case "LQPT":
          name = "乐企平台";
          break;
        default:
          break;
      }
      return name;
    },
  }
}
</script>

<style scoped lang="scss">
.page-main {
  /*padding: 24px 24px 32px 24px;*/
  /*position: relative;*/

  .page-body {

    font-size: 14px;
    color: #333333;
    position: absolute;
    top: 24px;
    right: 0px;
    padding-right: 24px;
    bottom: 100px;
    left: 24px;
    overflow-y: scroll;
    overflow-x: hidden;

    .subject {
      font-size: 18px;
      font-weight: 600;
      line-height: 24px;
      padding-bottom: 24px;
    }

    .prop-row {
      line-height: 36px;

      span.prop {
        color: #666666;
      }
    }

    .prop-tbl {
      text-align: center;
    }

    .prop-tbl-a {
      background-color: #EEEEEE;
    }

    .prop-tbl-b {
      background-color: #FFFFFF;
    }

    .prop-tbl .el-col {
      border-left: 1px solid #D8D8D8;
    }

    .prop-tbl .el-col:first-child {
      border-left: none;
    }

    .split-line {
      height: 1px;
      background-color: #CCCCCC;
      margin: 24px 0px;
    }

    .operateGuide {
      width: 56px;
      height: 20px;
      font-size: 14px;
      font-family: PingFangSC-Medium, PingFang SC;
      font-weight: 500;
      color: #F5222D;
      line-height: 20px;
    }

    .operate {
      width: 318px;
      height: 20px;
      font-size: 14px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #666666;
      line-height: 20px;
    }
  }
}
</style>
