<template>
  <div class="content-wrap">
    <div class="content-select">
      <div class="select-content">
        <el-form ref="form" :model="filter" class="cus_form">
          <el-row>
            <el-col :span="6">
              <el-form-item label="组织名称">
                <organization-select :is-add-all="false" :model="filter" allLabel="请选择组织"> </organization-select>
              </el-form-item>
            </el-col>
            <el-col :span="6">
              <el-form-item label="认证方式" name="authorizeMode">
                <el-select v-model="filter.authorizeMode" placeholder="请选择">
                  <el-option label="全部" value=" "></el-option>
                  <el-option label="电子税务局" value="DZSJ"></el-option>
                  <el-option label="乐企平台" value="LQPT"></el-option>
                  <!-- <el-option label="税盘" value="ZS"></el-option>
                  <el-option label="UKey服务器" value="UK"></el-option>
                  <el-option label="软证书" value="RZS"></el-option>
                  <el-option label="SD-R-X" value="RPA"></el-option> -->
                </el-select>
              </el-form-item>
            </el-col>
            <el-col :span="6">
              <el-form-item label="启用状态" name="enabled">
                <el-select v-model="filter.enabled" placeholder="请选择">
                  <el-option label="全部" value=" "></el-option>
                  <el-option label="启用" :value="true"></el-option>
                  <el-option label="禁用" :value="false"></el-option>
                </el-select>
              </el-form-item>
            </el-col>
            <el-col :span="3">
              <el-form-item>
                <select-button @reset="handleResetForm" @select="submitForm"></select-button>
              </el-form-item>
            </el-col>
          </el-row>
        </el-form>
      </div>
    </div>
    <div class="content-main">
      <div class="table">
        <el-table :data="tableData" stripe border v-loading="tableLoading" :header-cell-style="handleHeaderCellStyle" style="width: 100%">
          <el-table-column type="index" label="序号"></el-table-column>
          <el-table-column prop="orgName" label="组织名称" show-overflow-tooltip min-width="180"></el-table-column>
          <el-table-column prop="orgTaxNo" label="组织税号"></el-table-column>
          <el-table-column label="认证方式">
            <template slot-scope="scope">
              <span>{{ handDeductAuthorizeMode(scope.row.authorizeMode) }}</span>
            </template>
          </el-table-column>
          <el-table-column prop="" label="设置状态">
            <template slot-scope="scope">
              <span>{{ scope.row.authorizeMode != null ? '已设置' : '未设置' }}</span>
            </template>
          </el-table-column>
          <el-table-column label="启用状态">
            <template slot-scope="scope">
              <span>{{ scope.row.enabled == true ? '启用' : '禁用' }}</span>
            </template>
          </el-table-column>
          <el-table-column prop="operate" label="操作">
            <template slot-scope="scope">
              <el-button type="text" size="medium" @click="handleDetailEdit(scope.row)"> 编辑 </el-button>
              <el-button type="text" size="medium" @click="handleDetail(scope.row)"> 详情 </el-button>
            </template>
          </el-table-column>
        </el-table>
      </div>
      <div class="content-page">
        <el-pagination background @size-change="handleSizeChange" @current-change="handleCurrentChange" :current-page.sync="paging.current" :page-sizes="pageSizes" :page-size="paging.size" layout="total, sizes, prev, pager, next" :total="paging.total"> </el-pagination>
      </div>
    </div>
    <el-drawer append-to-body :visible.sync="detailVisible" :direction="direction" size="45%" :with-header="false">
      <certification-income :record="record" :recordLoading="recordLoading"></certification-income>
    </el-drawer>
    <div class="isShowEditDialog">
      <el-dialog title="编辑认证方式" :visible.sync="isShowEditDialog" width="40%" :close-on-click-modal="false" :close-on-press-escape="false" v-loading="editLoading">
        <el-form :model="setting" :rules="rules" ref="ruleForm" label-width="140px">
          <el-form-item label="组织名称" prop="orgName">
            <el-col>{{ setting.orgName }}</el-col>
          </el-form-item>
          <el-form-item label="组织税号" prop="orgTaxNo">
            <el-col>{{ setting.orgTaxNo }}</el-col>
          </el-form-item>
          <el-form-item label="进项认证功能">
            <el-switch v-model="setting.enabled" active-text="启用" inactive-text="禁用" active-color="#13CE66" :disabled="setting.authorizeMode == null" inactive-color="#FF4949"> </el-switch>
          </el-form-item>
          <el-form-item label="授权认证方式">
            <el-radio-group v-model="setting.authorizeMode">
              <el-radio label="DZSJ">电子税务局</el-radio>
              <el-radio label="LQPT">乐企平台</el-radio>
              <!-- <el-radio label="ZS">税盘</el-radio>
              <el-radio label="UK">UKey服务器</el-radio>
              <el-radio label="RZS">软证书</el-radio>
              <el-radio label="RPA">SD-R-X</el-radio> -->
            </el-radio-group>
          </el-form-item>
          <div v-show="setting.authorizeMode == 'ZS'">
            <el-form-item label="税盘密码" prop="password">
              <el-input v-model="setting.password" maxlength="16" class="formSelect"></el-input>
            </el-form-item>
            <el-form-item label="多啦客户端账号" prop="agentUsername">
              <el-input v-model="setting.agentUsername" maxlength="16" class="formSelect"></el-input>
            </el-form-item>
            <el-form-item label="多啦客户端密码" prop="agentPassword">
              <el-input v-model="setting.agentPassword" maxlength="16" class="formSelect"></el-input>
            </el-form-item>
          </div>
          <div v-show="setting.authorizeMode == 'UK'">
            <el-form-item label="开票终端标识" prop="identifier">
              <el-input v-model="setting.identifier" maxlength="20" class="formSelect"></el-input>
            </el-form-item>
          </div>
          <div v-show="setting.authorizeMode == 'RZS'">
            <el-row class="prop-row" :gutter="20">
              <el-col
                ><span class="prop"></span>
                <el-form-item label="开通状态">
                  <el-switch v-model="setting.activated" active-text="已开通" inactive-text="未开通" active-color="#13CE66" inactive-color="#FF4949"> </el-switch>
                </el-form-item>
              </el-col>
            </el-row>
          </div>
          <div v-show="setting.authorizeMode == 'RPA'">
            <el-form-item label="key" prop="xsdAppId">
              <el-input v-model="setting.xsdAppId"  class="formSelect"></el-input>
            </el-form-item>
            <el-form-item label="app_secret" prop="xsdAppSecret">
              <el-input v-model="setting.xsdAppSecret" class="formSelect"></el-input>
            </el-form-item>
          </div>
          <div class="dialog-footer">
            <el-button type="default" @click="isShowEditDialog = false">取消</el-button>
            <el-button type="primary" @click="handleUpdateIncome">保存</el-button>
          </div>
        </el-form>
      </el-dialog>
    </div>
  </div>
</template>

<script>
import OrganizationSelect from '@/components/widgets/OrganizationSelect';
import SelectButton from '@/components/buttons/SelectButton';
import { ReqCertificationGetDetail, CertificationGetListByPage, deductIncomeSetting, settingTaxno, deductIncomeSettingAdd } from '@/service/certification';
import { changeMethods, pagingMethods, tableStyle } from '@/util/mixins';
import CertificationIncome from '@/views/income-selection/CertificationIncome.vue';

export default {
  name: 'Certification',
  components: {
    OrganizationSelect,
    SelectButton,
    CertificationIncome
  },
  mixins: [pagingMethods, tableStyle, changeMethods],
  data() {
    return {
      formLabelWidth: '120px',
      isShowEditDialog: false,
      filter: {
        id: '',
        orgId: '',
        authorizeMode: '',
        enabled: ''
      },
      setting: {
        id: '',
        orgId: '',
        tenantId: '',
        orgName: '',
        orgTaxNo: '',
        authorizeMode: '',
        password: '',
        agentUsername: '',
        agentPassword: '',
        isMock: '',
        enabled: true,
        identifier: '',
        activated: false,
        xsdAppSecret: '',
        xsdAppId: ''
      },
      editLoading: false,
      paging: {
        current: 1,
        size: 15,
        total: 0,
        searchCount: true,
        pages: 1
      },
      pageSizes: [15, 30, 50, 100],
      tableLoading: false,
      tableData: [],
      //详情
      record: {},
      recordId: 0,
      recordLoading: false,
      incomeAuthorizeMode: [],
      recordsLoading: false,
      detailVisible: false,
      direction: 'rtl',
      params: {
        orgName: ''
      },
      rules: {
        password: [{ required: true, message: '请输入税盘密码', trigger: 'blur' }],
        agentUsername: [{ required: true, message: '请输入多啦客户端账号', trigger: 'blur' }],
        agentPassword: [{ required: true, message: '请输入多啦客户端密码', trigger: 'blur' }],
        identifier: [{ required: true, message: '请输入开票终端标识', trigger: 'blur' }],
        xsdAppSecret: [{ required: true, message: '请输入机器人X版AppSecret', trigger: 'blur' }],
        xsdAppId: [{ required: true, message: '请输入机器人X版Key', trigger: 'blur' }]
      },
      enabledStatusList: [
        { label: '全部', value: '' },
        { label: '启用', value: 'true' },
        { label: '禁用', value: 'false' }
      ],
      authorizeStatusList: [
        { label: '全部', value: '' },
        { label: '已设置', value: '1' },
        { label: '未设置', value: '-1' }
      ]
    };
  },
  created() {
    this.incomeAuthorizeMode = this.$store.state.enums.IncomeAuthorizeMode;
    this.refreshTable();
  },
  methods: {
    async saveIncomeSetting() {
      let settingId = this.setting.id
      if (settingId && settingId > 0) {
        let res = await deductIncomeSetting(this.setting);
        if (res.success) {
          this.editLoading = false;
          this.isShowEditDialog = false;
          this.toast('修改进项信息成功', 'success');
          this.refreshTable();
        } else {
          this.editLoading = false;
        }
      }else {
        let res = await deductIncomeSettingAdd(this.setting);
        if (res.success) {
          this.editLoading = false;
          this.isShowEditDialog = false;
          this.toast('保存进项信息成功', 'success');
          this.refreshTable();
        } else {
          this.editLoading = false;
        }
      }
    },
    handleUpdateIncome() {
      if (this.setting.authorizeMode == 'ZS') {
        let validateList = [];
        this.$refs.ruleForm.validateField(['password', 'agentPassword', 'agentUsername'], (msg) => {
          validateList.push(msg);
        });
        if (validateList.every((item) => item === '')) {
          this.editLoading = true;
          this.saveIncomeSetting();
        } else {
          return false;
        }
      }
      if (this.setting.authorizeMode == 'UK') {
        this.$refs.ruleForm.validateField('identifier', (msg) => {
          if (!msg) {
            this.editLoading = true;
            this.saveIncomeSetting();
          } else {
            return false;
          }
        });
      }
      if (this.setting.authorizeMode == 'RZS') {
        this.saveIncomeSetting();
        this.editLoading = true;
      }
      if (this.setting.authorizeMode == 'RPA') {
        let validateList = [];
        this.$refs.ruleForm.validateField(['xsdAppId', 'xsdAppSecret'], (msg) => {
          validateList.push(msg);
        });
        if (validateList.every((item) => item === '')) {
          this.editLoading = true;
          this.saveIncomeSetting();
        } else {
          return false;
        }
      }

      
      if (this.setting.authorizeMode == 'DZSJ') {
        this.saveIncomeSetting();
        this.editLoading = true;
      }
      
      if (this.setting.authorizeMode == 'LQPT') {
        this.saveIncomeSetting();
        this.editLoading = true;
      }

    },
    async handleDetailEdit(v) {
      // this.setting = JSON.parse(JSON.stringify(v));
      // if (this.setting.enabled !== true) {
      //   this.setting.enabled = false;
      // }
      this.isShowEditDialog = true;
      let res = await settingTaxno(v.orgTaxNo)
      if (res.success) {
          this.setting = res.data
          //新增时使用row的tenantId和orgId
          if (this.setting.tenantId == 0 && this.setting.orgId == 0) {
            this.setting.tenantId = row.tenantId
            this.setting.orgId = row.orgId
          }
        }
      console.log(res,'res')
    },
    // 构参提交表单
    submitForm() {
      console.log('选择组织' + this.filter.orgId);
      this.refreshTable();
    },
    // 重置表单
    handleResetForm() {
      this.filter = {
        orgId : '',
        authorizeMode : '',
        enabled : '',
      }
    },
    // 表格渲染
    refreshTable() {
      this.recordsLoading = true;
      CertificationGetListByPage({ filter: this.filter, paging: this.paging }).then((res) => {
        if (res.success) {
          this.paging = res.data;
          this.tableData = res.data.records;
        }
        this.recordsLoading = false;
      });
    },
    // 详情展示
    handleDetail(row) {
      this.getDetail(row.orgTaxNo);
      this.detailVisible = true;
    },
    getDetail(orgTaxNo) {
      this.recordLoading = true;
      ReqCertificationGetDetail(orgTaxNo)
        .then((res) => {
          if (res.success) {
            if (res.data == null) {
              this.recordLoading = false;
              return;
            }
            this.record = res.data;
          }
          this.recordLoading = false;
        })
        .catch(() => {
          this.recordLoading = false;
        });
    },
    //认证方式
    handDeductAuthorizeMode(value) {
      let name = "";
      switch (value) {
        case "ZS":
          name = "助手";
          break;
        case "UK":
          name = "助手";
          break;
        case "RZS":
          name = "软证书";
          break;
        case "RPA":
          name = "SD-R-X";
          break;
        case "DZSJ":
          name = "电子税务局";
          break;
        case "LQPT":
          name = "乐企平台";
          break;
        default:
          break;
      }
      return name;
    },

  }
};
</script>

<style scoped lang="scss">
.select-content {
  padding: 0;
  .cus_form {
    padding: 24px;
    width: 100%;
  }
}
.isShowEditDialog {
  ::v-deep .el-dialog .el-dialog__body {
    text-align: left;
  }
  ::v-deep .el-input {
    margin-left: 32px;
  }
  ::v-deep .el-dialog .el-dialog__body {
    padding: 30px 20px;
  }
  ::v-deep .el-form-item {
    margin-bottom: 20px;
  }
  ::v-deep .el-form-item__error {
    top: 30px;
    left: 30px;
  }
}
.dialog-footer {
  text-align: right;
}
::v-deep .el-form-item__label {
  text-align: left;
}
.inputBox {
  width: 278px;
}
</style>
